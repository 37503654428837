$(function () {
  // VIDEO
  window.setTimeout(() => {
    $(".preloader").hide();
    $(".preloader").css("display", "none");
  }, 2000);

  // scroller down
  $(".gotoaction .btn").click(function (e) {
    if ($(this).hasClass("left")) {
      $(".half.first").addClass("opened");
      $(".half.second").addClass("shrinked shrinked-italy");

      openAsia();
      closeItaly();
    } else {
      $(".half.second").addClass("opened");
      $(".half.first").addClass("shrinked shrinked-asia");
      openItaly();
      closeAsia();
    }

    $("html").css("height", "auto");
    $(".mid, .half > .horizont, .gotoaction, .darker").hide();
  });

  // Slider
  let slideIndex = 1;

  function plusSlides(n) {
    showSlides((slideIndex += n));
  }

  function showSlides(n) {
    let i;

    $(".slider__slide").css("display", "none");

    let slides = [];
    if ($(".slider__container").hasClass("slider__container-asia")) {
      slides = $(".slider__slide-asia");
    } else if ($(".slider__container").hasClass("slider__container-italy")) {
      slides = $(".slider__slide-italy");
    } else {
      return;
    }

    if (n > slides.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slides.length;
    }

    $("#currentImg").text(slideIndex);
    $("#imgListLength").text(slides.length);

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slides[slideIndex - 1].style.display = "block";
  }

  $("#arrowPrev").click(() => {
    plusSlides(-1);
  });
  $("#arrowNext").click(() => {
    plusSlides(1);
  });

  $(".slider__slide").click(() => {
    plusSlides(1);
  });

  // To main page
  function openDoors() {
    $(".main__footer").css("background-image", "");
    $("#slider .slider__container").removeClass("slider__container-asia");
    $("#slider .slider__container").removeClass("slider__container-italy");

    $(".main__footer").removeClass("footer-italy");
    $(".main__footer").removeClass("footer-asia");

    $("#nav").removeClass("seesaw");
    $("#nav").removeClass("seesaw-italy");
    $("#nav").removeClass("seesaw-asia");

    $(".middle.asia").hide("slow");
    $(".culture.asia").hide("slow");
    $(".services.asia").hide("slow");

    $(".middle.italy").hide("slow");
    $(".culture.italy").hide("slow");
    $(".services.italy").hide("slow");

    window.setTimeout(function () {
      $(".half.second .switch-logo").removeClass("visible switch-logo_italy");
      $(".half.first .content").removeClass("visible");
      $(".half.first .flower").removeClass("visible");
      $(".half.first .dish-bg").removeClass("visible");
      $(".half.first .vertical").removeClass("visible");
      $(".half.first").css("background-image", "url(./img/asiandoor.jpg)");
      $(".half.first .burger, .half.first .pin").removeClass("visible");
      $(".half.first .address").removeClass("visible");
      $(".half.first .doorlogo").removeClass("visible");
      $(".half.first .socials").removeClass("visible");
      $(".half.first .discover").removeClass("visible");

      $(".half.first .switch-logo").removeClass("visible switch-logo_asia");
      $(".half.second .content").removeClass("visible");
      $(".half.second .flower").removeClass("visible");
      $(".half.second .dish-bg").removeClass("visible");
      $(".half.second .vertical").removeClass("visible");
      $(".half.second").css("background-image", "url(./img/italdoor.jpg)");
      $(".half.second .burger, .half.second .pin").removeClass("visible");
      $(".half.second .address").removeClass("visible");
      $(".half.second .doorlogo").removeClass("visible");
      $(".half.second .socials").removeClass("visible");
      $(".half.second .discover").removeClass("visible");
      $(".header__btn").removeClass("header__btn-active");
    }, 500); //<-- Delay in milliseconds
  }

  function toMainPage() {
    $(".half.first").removeClass("opened");
    $(".half.second").removeClass("opened");
    $(".half.second").removeClass("shrinked shrinked-italy");
    $(".half.first").removeClass("shrinked shrinked-asia");

    openDoors();

    $("html").css("height", "100vh");
    $(".mid, .half > .horizont, .gotoaction, .darker").show();
  }

  $(".header__btn").click(() => {
    toMainPage();
  });

  // Open Asian Part
  function openAsia() {
    $(".main__footer").css(
      "background-image",
      "url(./img/bg-gradient-asia.jpg)"
    );
    $("#slider .slider__container").addClass("slider__container-asia");
    $("#slider .slider__container").removeClass("slider__container-italy");
    $(".main__footer").removeClass("footer-italy");
    $(".main__footer").addClass("footer-asia");
    $("#nav").addClass("seesaw");
    $("#nav").removeClass("seesaw-italy");
    $("#nav").addClass("seesaw-asia");
    $(".middle.asia").show("slow");
    $(".culture.asia").show("slow");
    $(".services.asia").show("slow");

    showSlides(slideIndex);

    window.setTimeout(function () {
      $(".half.second .switch-logo").addClass("visible switch-logo_italy");
      $(".half.first .content").addClass("visible");
      $(".half.first .flower").addClass("visible");
      $(".half.first .dish-bg").addClass("visible");
      $(".half.first .vertical").addClass("visible");
      $(".half.first").css(
        "background-image",
        "url(./img/bg-gradient-asia.jpg)"
      );
      $(".half.first .burger, .half.first .pin").addClass("visible");
      $(".half.first .address").addClass("visible");
      $(".half.first .doorlogo").addClass("visible");
      $(".half.first .socials").addClass("visible");
      $(".half.first .discover").addClass("visible");
      if (window.innerWidth < 768) {
        $(".header__btn").addClass("header__btn-active");
      }
    }, 500); //<-- Delay in milliseconds
  }

  function openItaly() {
    $(".main__footer").css(
      "background-image",
      "url(./img/footer-bg-italy.jpg)"
    );
    $("#slider .slider__container").addClass("slider__container-italy");
    $("#slider .slider__container").removeClass("slider__container-asia");
    showSlides(slideIndex);
    $(".main__footer").removeClass("footer-asia");
    $(".main__footer").addClass("footer-italy");
    $("#nav").addClass("seesaw");
    $("#nav").removeClass("seesaw-asia");
    $("#nav").addClass("seesaw-italy");
    $(".middle.italy").show("slow");
    $(".culture.italy").show("slow");
    $(".services.italy").show("slow");

    window.setTimeout(function () {
      $(".half.first .switch-logo").addClass("visible switch-logo_asia");
      $(".half.second .content").addClass("visible");
      $(".half.second .flower").addClass("visible");
      $(".half.second .dish-bg").addClass("visible");
      $(".half.second .vertical").addClass("visible");
      $(".half.second").css("background-image", "url(./img/header-italy.jpg)");
      $(".half.second .burger, .half.second .pin").addClass("visible");
      $(".half.second .address").addClass("visible");
      $(".half.second .doorlogo").addClass("visible");
      $(".half.second .socials").addClass("visible");
      $(".half.second .discover").addClass("visible");
      if (window.innerWidth < 768) {
        $(".header__btn").addClass("header__btn-active");
      }
    }, 500); //<-- Delay in milliseconds
  }

  function closeItalySlider() {
    window.setTimeout(function () {
      $("#slider .slider__container").removeClass("slider__container-italy");
      $(".half.first .switch-logo").removeClass("visible switch-logo_asia");
      $(".half.second .content").removeClass("visible");
      $(".half.second .flower").removeClass("visible");
      $(".half.second .dish-bg").removeClass("visible");
      $(".half.second .vertical").removeClass("visible");
      $(".half.second").css("background-image", "url(./img/italdoor.jpg)");
      $(".half.second .burger, .half.second .pin").removeClass("visible");
      $(".half.second .address").removeClass("visible");
      $(".half.second .doorlogo").removeClass("visible");
      $(".half.second .socials").removeClass("visible");
      $(".half.second .discover").removeClass("visible");
    }, 500);
  }

  function closeAsiaSlider() {
    window.setTimeout(function () {
      $("#slider .slider__container").removeClass("slider__container-asia");
      $(".half.second .switch-logo").removeClass("visible switch-logo_italy");
      $(".half.first .content").removeClass("visible");
      $(".half.first .flower").removeClass("visible");
      $(".half.first .dish-bg").removeClass("visible");
      $(".half.first .vertical").removeClass("visible");
      $(".half.first").css("background-image", "url(./img/asiandoor.jpg)");
      $(".half.first .burger, .half.first .pin").removeClass("visible");
      $(".half.first .address").removeClass("visible");
      $(".half.first .doorlogo").removeClass("visible");
      $(".half.first .socials").removeClass("visible");
      $(".half.first .discover").removeClass("visible");
    }, 500); //<-- Delay in milliseconds
  }

  function closeItaly() {
    closeItalySlider();
    $(".middle.italy").hide("slow");
    $(".culture.italy").hide("slow");
    $(".services.italy").hide("slow");
  }

  function closeAsia() {
    closeAsiaSlider();
    $(".middle.asia").hide("slow");
    $(".culture.asia").hide("slow");
    $(".services.asia").hide("slow");
  }

  function swithToItaly() {
    closeAsia();
    openItaly();
    $(".half.first").removeClass("opened").addClass("shrinked shrinked-asia");
    $(".half.second").removeClass("shrinked shrinked-italy").addClass("opened");
  }

  function swithToAsia() {
    closeItaly();
    openAsia();
    $(".half.first").removeClass("shrinked shrinked-asia").addClass("opened");
    $(".half.second").removeClass("opened").addClass("shrinked shrinked-italy");
  }

  // Switch between asia/italy
  $(".italy .switcher").click(function (e) {
    swithToAsia();
  });

  $(".asia .switcher").click(function (e) {
    swithToItaly();
  });

  $(".half").click(function (e) {
    if ($(this).hasClass("shrinked")) {
      if ($(this).hasClass("first")) {
        swithToAsia();
      } else {
        swithToItaly();
      }
    }
  });

  //OPEN/CLOSE POPUP OVERLAY
  $("#menu").click(function (e) {
    $("#popup").toggleClass("visible");
    $("body").toggleClass("noScroll");
    $(".doors__nav").toggleClass("nav-popup");
    if (window.innerWidth < 768 && $(".half").hasClass("opened")) {
      $(".header__btn").toggleClass("header__btn-active");
    }
  });

  function closePopup() {
    $("#popup").removeClass("visible");
    $("body").removeClass("noScroll");
    $(".doors__nav").removeClass("nav-popup");
  }

  $("#toMain").click(() => {
    closePopup();
    toMainPage();
  });

  $("#menu-asia").click(() => {
    closePopup();

    if ($(".half").hasClass("opened")) {
      swithToAsia();
    } else {
      $(".half.first").addClass("opened");
      $(".half.second").addClass("shrinked shrinked-italy");

      openAsia();
      closeItaly();
      $("html").css("height", "auto");
      $(".mid, .half > .horizont, .gotoaction, .darker").hide();
    }
  });

  $("#menu-italy").click(() => {
    closePopup();
    if ($(".half").hasClass("opened")) {
      swithToItaly();
    } else {
      $(".half.second").addClass("opened");
      $(".half.first").addClass("shrinked shrinked-asia");
      openItaly();
      closeAsia();

      $("html").css("height", "auto");
      $(".mid, .half > .horizont, .gotoaction, .darker").hide();
    }
  });

  // scroller down
  $(".discover").click(function (e) {
    $("html, body").animate(
      {
        scrollTop: $("#doors").prop("scrollHeight"),
      },
      1000
    );
    return false;
  });

  // backontop
  $(".footer__btn").click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      1500
    );
    return false;
  });

  var map = L.map("map").setView([50.3729994, 30.5458293], 13);
  L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
    {
      subdomains: "abcd",
      maxZoom: 19,
    }
  ).addTo(map);

  // marker
  // var marker = L.marker([50.3729994,30.5458293]).addTo(map);
  // marker.bindPopup("1 ЧП «КИЕВ-РЕСУРС» <br>КИЕВСКИЙ ЦЕМЕНТНЫЙ ЗАВОД").openPopup();

  // onScroll
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      $("#nav").addClass("onScroll");
    } else {
      $("#nav").removeClass("onScroll");
    }
  }
});